import React from 'react'
import { TEXT_STYLES } from 'constants/text_styles'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const FeatureHighlightsWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 32px 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const FeatureHighlight = styled.li`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const FeatureDescription = styled.div`
  h4 {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    font-weight: 500;
    color: ${props => props.theme.colors.primary};
  }
  p {
    margin: 0;
    ${TEXT_STYLES.BodyBlack}
  }
`

export const FeatureHighlightList = ({ list }) => (
  <FeatureHighlightsWrapper>
    {list.map(({ icon, title, description }, i) => {
      return (
        <FeatureHighlight key={i}>
          <div>{icon}</div>
          <FeatureDescription>
            <h4>{title}</h4>
            <p>{description}</p>
          </FeatureDescription>
        </FeatureHighlight>
      )
    })}
  </FeatureHighlightsWrapper>
)

FeatureHighlightList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.any),
        PropTypes.string
      ]).isRequired,
      description: PropTypes.oneOfType([
        PropTypes.objectOf(PropTypes.any),
        PropTypes.string
      ]).isRequired
    })
  ).isRequired
}
