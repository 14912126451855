import React from 'react'
import styled from 'styled-components'

import { toggleProfitCalculator } from 'actions/index'
import { productType } from 'src/js/types/product'
import { Price } from './Price'

const StyledProductDatabasePriceCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const PriceCell = ({ product }) => {
  return (
    <StyledProductDatabasePriceCell>
      <Price
        product={product}
        toggleProfitCalculator={toggleProfitCalculator}
      />
    </StyledProductDatabasePriceCell>
  )
}

PriceCell.propTypes = {
  product: productType.isRequired
}
