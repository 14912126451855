import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'

import { Tooltip } from '@junglescout/edna'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'
import { NakedButton } from 'ui_elements/NakedButton/NakedButton'
import { isValidValue } from 'helpers/values'
import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'

const InCellButton = styled(NakedButton)`
  display: inline-block;
`

export const ProductDatabaseListingdateCell = ({ product }) => {
  const { t } = useTranslation('product_database')
  const { listedAt, estimatedListedAt } = product
  if (!isValidValue(listedAt) && !isValidValue(estimatedListedAt))
    return renderNaTooltip(DATABASE_TOOLTIPS.nullProductData.listedAt)
  return listedAt ? (
    <div>
      <Tooltip
        size="100px"
        side="bottom"
        content={
          <div>
            {t(
              'product_database:DatabaseTable.listingdateTooltip',
              'MM/DD/YYYY'
            )}
          </div>
        }>
        <InCellButton>
          {moment(parseInt(listedAt)).format('MM/DD/YYYY')}
        </InCellButton>
      </Tooltip>
    </div>
  ) : (
    <div>
      <Tooltip
        size="200px"
        side="bottom"
        content={
          <div>
            {t(
              'product_database:DatabaseTable.estimatedListingdateTooltip',
              'MM/DD/YYYY, Since Amazon does not provide this data, we will estimate by using the earliest review date or earliest Q&A date'
            )}
          </div>
        }>
        <InCellButton>
          {moment(parseInt(estimatedListedAt)).format('MM/DD/YYYY')}
        </InCellButton>
      </Tooltip>
    </div>
  )
}

const renderNaTooltip = (content, value = undefined) => {
  return <NoDataTooltip value={value} content={content} />
}

ProductDatabaseListingdateCell.propTypes = {
  product: PropTypes.object.isRequired
}
