import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { refreshProductVariant } from 'actions/database'

import { productType } from 'types/product'

import {
  ClickableIcon,
  LoadingPlaceholderGradient,
  Tooltip
} from '@junglescout/edna'

import { DefaultAppOnly } from 'src/js/containers/common/DefaultAppOnly/DefaultAppOnly'

import { AddGroupButton } from './AddGroupButton'
import { ProductTitle } from './ProductTitle'

const ActionsWrapper = styled.div`
  display: flex;
  align-self: center;
  gap: 2px;
  position: relative;
  right: 10px;
`
const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: auto 10px;
`
const ProductWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-left: ${props => (props.isVariant ? '55px' : '0px')};
`
const ImageLoadingPlaceholder = styled(LoadingPlaceholderGradient)`
  margin-top: 0 !important;
`

export const ProductTitleCell = ({ isVariant, product }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['product_database'])
  const { isLoading, asin, country } = product

  const { database, globalData } = useSelector(store => store)

  const { appType } = globalData
  const {
    data: { selectedCountry }
  } = database

  if (isLoading) {
    return (
      <ProductWrapper isVariant={isVariant}>
        <ImageLoadingPlaceholder width="42px" height="42px" />
        <LoadingWrapper>
          <LoadingPlaceholderGradient width="80%" height="5px" />
        </LoadingWrapper>
      </ProductWrapper>
    )
  }

  return (
    <>
      {isVariant && (
        <ActionsWrapper>
          <DefaultAppOnly>
            <AddGroupButton product={product} />
          </DefaultAppOnly>
          <Tooltip
            content={t(
              'common:RefreshProductButton.refreshProductTooltip',
              'Refresh Product Data'
            )}
            side="top">
            <span>
              <ClickableIcon
                name="REFRESH"
                padding="4px"
                onClick={() => {
                  dispatch(
                    refreshProductVariant({
                      asin,
                      country,
                      parentAsin: product.scrapedParentAsin
                    })
                  )
                }}
              />
            </span>
          </Tooltip>
        </ActionsWrapper>
      )}
      <ProductTitle
        selectedCountry={selectedCountry}
        appType={appType}
        product={product}
        isProductVariant={isVariant}
      />
    </>
  )
}

ProductTitleCell.defaultProps = {
  isVariant: false
}

ProductTitleCell.propTypes = {
  isVariant: PropTypes.bool,
  product: productType.isRequired
}
