import React from 'react'
import { formatProductEstimatedRevenue } from 'src/js/helpers/product_database/formatters'
import { productType } from 'src/js/types/product'

export const MonthlyRevenueCell = ({ product }) => (
  <div>{formatProductEstimatedRevenue(product?.estRevenue, product)}</div>
)

MonthlyRevenueCell.propTypes = {
  product: productType.isRequired
}
