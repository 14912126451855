import React from 'react'
import styled from 'styled-components'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'

import { AmazonDisabled } from 'icons/AmazonDisabled/AmazonDisabled'
import { Tooltip } from '@junglescout/edna'

const SharedBsrTooltipContent = styled.div`
  display: flex;
`
const SharedBsrAmzIcon = styled(AmazonDisabled)`
  flex-shrink: 0;
  margin-top: 2px;
`

export function SharedBsrTooltip() {
  return (
    <Tooltip
      content={
        <SharedBsrTooltipContent>
          <SharedBsrAmzIcon width="20px" height="20px" />
          {DATABASE_TOOLTIPS.nullProductData.sharedBsr}
        </SharedBsrTooltipContent>
      }
      side="bottom"
      size="medium">
      <div>--</div>
    </Tooltip>
  )
}
