import styled from 'styled-components'
import { Text } from '@junglescout/edna'

export const Subtitle = styled(Text)`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
`
