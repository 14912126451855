import React from 'react'
import styled from 'styled-components'

import { StarRating } from 'ui_elements/StarRating/StarRating'
import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { productType } from 'src/js/types/product'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const RatingNumber = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const RatingCell = ({ product }) => {
  const { rating, nReviews } = product

  if (!rating && !nReviews) {
    return (
      <NoDataTooltip
        content={<span>{DATABASE_TOOLTIPS.nullProductData.rating}</span>}
        size="medium"
      />
    )
  }

  const ratingNumber = parseFloat(rating || 0)
  return (
    <RatingContainer>
      <RatingNumber>{ratingNumber.toFixed(1)}</RatingNumber>
      <StarRating rating={ratingNumber} size="15" />
    </RatingContainer>
  )
}

RatingCell.propTypes = {
  product: productType.isRequired
}
