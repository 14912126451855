import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import styled, { useTheme } from 'styled-components'

import { viewProductInTracker } from 'actions/tracker'
import { addToTracker } from 'actions/database'

import { COMMON_TOOLTIPS } from 'constants/product_database/tooltips'

import { productType } from 'types/product'

import { ClickableIcon, LoadingIcon, Tooltip } from '@junglescout/edna'

import { AddGroupDropdown } from 'ui_elements/AddGroupDropdown/AddGroupDropdown'

const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:hover {
    background-color: ${props => props.theme.colors.grey200};
  }
`

export const AddGroupButton = ({ product }) => {
  const dispatch = useDispatch()
  const { colors } = useTheme()

  const { t } = useTranslation(['product_database'])

  const allTrackedProducts = useSelector(
    state => state.globalData?.allTrackedProducts
  )
  const pendingTracks = useSelector(state => state.tracker.pendingTracks)

  const { variants, id } = product

  const targetIds =
    variants?.length > 0 ? variants.map(variant => variant.id) : [id]

  const isLoadingTracker = pendingTracks.some(productId =>
    targetIds.some(targetId => productId === targetId)
  )

  if (isLoadingTracker) {
    return (
      <IconWrapper>
        <LoadingIcon size="16px" color="grey900" />
      </IconWrapper>
    )
  }

  const isTracked = allTrackedProducts.some(productId =>
    targetIds.some(targetId => productId === targetId)
  )

  if (isTracked) {
    return (
      <Tooltip side="top" content={COMMON_TOOLTIPS.addToTrackerButton.tracked}>
        <span>
          <ClickableIcon
            color={colors.green500}
            name="CHECK_CIRCLE"
            onClick={() => dispatch(viewProductInTracker(id))}
            padding="4px"
          />
        </span>
      </Tooltip>
    )
  }

  const onCreateAndAdd = (inputCategoryName, isPrivateCategory) =>
    dispatch(
      addToTracker({
        product,
        country: product.country,
        category: inputCategoryName,
        isNewCategory: true,
        isPrivateCategory,
        t
      })
    )

  const onCategorySelected = categoryName =>
    dispatch(
      addToTracker({
        product,
        category: categoryName,
        t,
        country: product.country
      })
    )

  return (
    <AddGroupDropdown
      onCategorySelected={onCategorySelected}
      onCreateAndAdd={onCreateAndAdd}
      createButtonText={t(
        'product_database:DatabaseTable.modalCreateButton',
        'Create & Add'
      )}>
      <span>
        <Tooltip
          content={t(
            'product_database:DatabaseTable.dropdownActions.addToTracker',
            'Add to Product Tracker'
          )}
          side="top">
          <span>
            <ClickableIcon name="PLUS_CIRCLE" padding="4px" />
          </span>
        </Tooltip>
      </span>
    </AddGroupDropdown>
  )
}

AddGroupButton.propTypes = {
  product: productType.isRequired
}
