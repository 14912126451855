import React from 'react'
import styled from 'styled-components'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'

import { productType } from 'src/js/types/product'

import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'
import { Subtitle } from './Subtitle'

const SellerContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const SellerNumber = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const SellersCell = ({ product }) => {
  const { nSellers, sellerName } = product

  if (!sellerName) {
    return (
      <NoDataTooltip
        content={<span>{DATABASE_TOOLTIPS.nullProductData.seller}</span>}
        size="medium"
      />
    )
  }

  return (
    <SellerContainer>
      <SellerNumber>{nSellers || 1}</SellerNumber>
      <Subtitle title={sellerName} variant="bodyXs" color="grey500">
        {sellerName}
      </Subtitle>
    </SellerContainer>
  )
}

SellersCell.propTypes = {
  product: productType.isRequired
}
