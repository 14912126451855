import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TYPOGRAPHY } from '@junglescout/styles-library'
import { Tooltip, ImageStack, Separator, Text } from '@junglescout/edna'

import { toLineBreakFriendly } from 'helpers/strings'
import { formatImageResolution } from 'helpers/images'
import { redirectToKWS } from 'helpers/url'

import { AmazonLink } from 'ui_elements/AmazonLink/AmazonLink'
import { TruncatedTextWithTooltip } from 'ui_elements/TruncatedTextWithTooltip/TruncatedTextWithTooltip'

import { trackPendoEvent } from 'services/pendo'
import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { sendSegmentAsinInKSSearchedEvent } from 'helpers/segment'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'

const TitleCellWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;

  &:hover {
    [class*='DropdownMenuWrapper'] {
      position: absolute;
      left: 50px;
    }
  }
`
const AsinLabel = styled(Text)`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`
const AsinBrandWrapper = styled.div`
  ${TYPOGRAPHY.bodyXs};
  color: ${props => props.theme.colors.grey600};
  display: flex;
  align-items: center;
`
const Title = styled(TruncatedTextWithTooltip)`
  ${TYPOGRAPHY.bodySm};
`
const TitleSection = styled.div`
  width: 100%;
`
const Brand = styled.div`
  /* without this the tooltip for no data gets squished */
  ${props =>
    props.hasBrand &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:  ${props.leftOffset ? '138px' : '195px'};
  `}
`
const Spacer = styled.div`
  width: 55px;
  min-width: 55px;
  max-width: 55px;
  content: '';
  flex-grow: 3;
`

export const ProductTitle = props => {
  const {
    product,
    appType,
    selectedCountry,
    productUrl,
    isProductVariant,
    leftOffset
  } = props
  const { name, imageUrl, brand, variantAsinsCount, product_url } = product

  const { t } = useTranslation(['product_database', 'generic'])

  const formattedImage = useMemo(() => formatImageResolution(imageUrl), [
    imageUrl
  ])
  const productName = useMemo(() => toLineBreakFriendly(name), [name])

  const badgeNumber = variantAsinsCount > 15 ? '+15' : variantAsinsCount
  const showStack = !isProductVariant && variantAsinsCount > 0

  const redirectToKeywordScout = e => {
    e.stopPropagation()
    sendSegmentAsinInKSSearchedEvent(
      appType,
      product,
      selectedCountry,
      'Product Database'
    )

    return redirectToKWS(product)
  }

  const formatAsin = () => {
    return (
      <Tooltip
        side="bottom"
        content={
          <div>
            {t(
              'product_database:DatabaseTable.asinTooltip',
              'Search on Keyword Scout'
            )}
          </div>
        }>
        <div>
          <AsinLabel
            onClick={redirectToKeywordScout}
            variant="bodyXs"
            color="grey600">
            {product.asin}
          </AsinLabel>
        </div>
      </Tooltip>
    )
  }

  return (
    <TitleCellWrapper>
      {leftOffset && <Spacer />}
      <ImageStack
        src={formattedImage}
        alt={name || 'n/a'}
        badgeNumber={badgeNumber > 0 ? badgeNumber : null}
        showStack={showStack}
        size="small"
      />
      <TitleSection>
        <Title numOfLines={1}>{productName}</Title>
        <AsinBrandWrapper>
          {formatAsin()}
          <Separator orientation="vertical" height="18px" margin="0 6px" />
          <Brand title={brand} hasBrand={!!brand} leftOffset={isProductVariant}>
            {brand || (
              <NoDataTooltip
                content={DATABASE_TOOLTIPS.nullProductData.brand}
                size="medium"
                side="right"
              />
            )}
          </Brand>
        </AsinBrandWrapper>
      </TitleSection>
      <AmazonLink
        className="pd_amazon_link"
        product={product}
        url={productUrl || product_url}
        tooltip={t('generic:ViewOnAmazon', 'View on Amazon')}
        onClick={() => {
          trackPendoEvent('Product Database - Product Open on Amazon')
        }}
      />
    </TitleCellWrapper>
  )
}

ProductTitle.defaultProps = {
  isProductVariant: false,
  leftOffset: false,
  productUrl: undefined,
  appType: 'default'
}

ProductTitle.propTypes = {
  appType: PropTypes.string,
  product: PropTypes.objectOf(PropTypes.any).isRequired,
  productUrl: PropTypes.string,
  leftOffset: PropTypes.bool,
  isProductVariant: PropTypes.bool,
  selectedCountry: PropTypes.string.isRequired
}
