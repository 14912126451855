import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Button, ButtonType } from '@junglescout/edna'

import { refreshProduct } from 'actions/database'
import { productType } from 'types/product'

const StyledButton = styled(Button)`
  padding: 0;
`

export const LastUpdatedCell = ({ product: { scrapedAt, asin, country } }) => {
  const { t } = useTranslation(['generic'])
  const dispatch = useDispatch()

  return (
    <div>
      <div>{scrapedAt || '-'}</div>
      <StyledButton
        btnType={ButtonType.PRIMARY_TEXT}
        iconName="REFRESH"
        margin="0 0 0 -1px"
        mini
        onClick={() => dispatch(refreshProduct({ asin, country }))}>
        {t('generic:RefreshData', 'Refresh Data')}
      </StyledButton>
    </div>
  )
}

LastUpdatedCell.propTypes = {
  product: productType.isRequired
}
