import React from 'react'
import { productType } from 'src/js/types/product'
import { ProductDatabaseListingdateCell } from 'src/js/components/product_database/ProductDatabaseListingdateCell/ProductDatabaseListingdateCell'

export const ListedAtCell = ({ product }) => {
  return <ProductDatabaseListingdateCell product={product} />
}

ListedAtCell.propTypes = {
  product: productType.isRequired
}
