import React from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { productType } from 'src/js/types/product'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'
import { QuestionTooltip } from 'ui_elements/QuestionTooltip/QuestionTooltip'

const ReviewsWrapper = styled.div`
  display: flex;

  [class^='IconWrapper'] {
    color: ${props => props.theme.colors.grey400};
  }
`

export const ReviewsCell = ({ product }) => {
  const { nReviews, variantAsinsCount } = product

  if (!nReviews) {
    return (
      <NoDataTooltip
        content={DATABASE_TOOLTIPS.nullProductData.reviews}
        size="medium"
      />
    )
  }

  if (variantAsinsCount) {
    return (
      <ReviewsWrapper>
        <div>{Math.round(product.nReviews)?.toLocaleString()}</div>
        <QuestionTooltip
          iconSize="16px"
          tooltipSize="170px"
          margin="0 0 0 4px"
          side="bottom"
          content={
            <Trans i18nKey="product_database:DatabaseTable.reviewsAverage">
              Average number of reviews for all variants
            </Trans>
          }
        />
      </ReviewsWrapper>
    )
  }

  return nReviews
}

ReviewsCell.propTypes = {
  product: productType.isRequired
}
