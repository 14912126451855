import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip, Button, ButtonType } from '@junglescout/edna'
import { TYPOGRAPHY } from '@junglescout/styles-library'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { isValidValue } from 'helpers/values'
import { parseCurrency } from 'helpers/currency'
import { sendCtaClickEvent } from 'helpers/segment'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'

const CalculationTextWrapper = styled.div`
  color: ${props => props.theme.colors.grey400};
  display: flex;
`

const StyledDiv = styled.div`
  display: inline-block;
  .tooltip__content {
    width: fit-content;
    white-space: nowrap;
  }
`

const InCellButton = styled(Button)`
  display: inline-block;
  ${TYPOGRAPHY.bodyXs}
  color: ${props => props.theme.colors.primary500};
  padding: 0 2px;
`

export const Price = props => {
  const dispatch = useDispatch()
  const { product, toggleProfitCalculator } = props
  const { price, currency_code, feeBreakdown = {} } = product
  const { totalFees = 0 } = feeBreakdown
  const { t } = useTranslation(['product_database'])

  const formatPrice = () => {
    if (isValidValue(price)) return parseCurrency(price, currency_code)

    return (
      <NoDataTooltip
        content={DATABASE_TOOLTIPS.nullProductData.price}
        size="medium"
      />
    )
  }

  const formatNetAndFees = () => {
    if (!isValidValue(price) && !isValidValue(feeBreakdown)) {
      return null
    }

    if (!isValidValue(price)) {
      return (
        <NoDataTooltip
          content={DATABASE_TOOLTIPS.nullProductData.noPriceNet}
          size="medium"
        />
      )
    }

    if (!isValidValue(feeBreakdown)) {
      return (
        <NoDataTooltip
          content={DATABASE_TOOLTIPS.nullProductData.noFeesNet}
          size="medium"
        />
      )
    }

    return (
      <Tooltip
        content={
          <StyledDiv>
            {t(
              'product_database:DatabaseTable.netTooltip',
              'Open Revenue Calculator'
            )}
          </StyledDiv>
        }>
        <InCellButton
          btnType={ButtonType.PRIMARY_TEXT}
          margin="0 -2px 0 0"
          mini
          onClick={e => {
            e.stopPropagation()
            dispatch(toggleProfitCalculator(true, product))
            sendCtaClickEvent({
              text: 'View Profit Calculator',
              subtext: product?.country,
              location: 'Product Database'
            })
          }}>
          {parseCurrency(price - totalFees, currency_code)}
          {totalFees > 0 && (
            <>
              <span>&nbsp;|&nbsp;</span>
              {parseCurrency(totalFees, currency_code)}
            </>
          )}
        </InCellButton>
      </Tooltip>
    )
  }

  return (
    <>
      {formatPrice()}
      <CalculationTextWrapper>{formatNetAndFees()}</CalculationTextWrapper>
    </>
  )
}

Price.propTypes = {
  product: PropTypes.PropTypes.objectOf(PropTypes.any).isRequired,
  toggleProfitCalculator: PropTypes.func.isRequired
}
