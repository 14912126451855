import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CheckBoxIcon } from 'icons/CheckBoxIcon/CheckBoxIcon'
import { toggleCheckAllRecommendedProducts } from 'actions/tracker/circularlyDependent'

class TrackerRecommendedCheckAll extends Component {
  render() {
    const { checkAllRecommended, numberCheckedRecommended } = this.props.tracker

    return (
      <CheckBoxIcon
        onClick={this.props.toggleCheckAllRecommendedProducts}
        checked={checkAllRecommended}
        partial={!checkAllRecommended && !!numberCheckedRecommended}
      />
    )
  }
}

const mapStateToProps = ({ tracker }) => {
  return { tracker }
}

export default connect(
  mapStateToProps,
  {
    toggleCheckAllRecommendedProducts
  }
)(TrackerRecommendedCheckAll)
