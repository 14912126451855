import React from 'react'
import styled from 'styled-components'

import { DATABASE_TOOLTIPS } from 'constants/product_database/tooltips'
import { dimensionFormatter } from 'helpers/formatters'
import { productType } from 'src/js/types/product'
import { NoDataTooltip } from 'ui_elements/NoDataTooltip/NoDataTooltip'
import { Subtitle } from './Subtitle'

const DimensionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DimensionsNumber = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DimensionsDescription = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DimensionsCell = ({ product }) => {
  const { length, width, height, tier, dimensionUnit } = product

  if (!length || !width || !height) {
    return (
      <NoDataTooltip
        size="medium"
        content={<span>{DATABASE_TOOLTIPS.nullProductData.dimensions}</span>}
      />
    )
  }

  return (
    <DimensionsContainer>
      <DimensionsNumber>
        {dimensionFormatter({ length, width, height, dimensionUnit })}
      </DimensionsNumber>
      <DimensionsDescription>
        {Boolean(tier) && (
          <Subtitle variant="bodyXs" color="grey500">
            {tier}
          </Subtitle>
        )}
      </DimensionsDescription>
    </DimensionsContainer>
  )
}

DimensionsCell.propTypes = {
  product: productType.isRequired
}
